import axios from 'axios';
import authHeader from '../helpers/authHeader';

const API_URL = 'https://api-dev.visitjablanica.com/api/';
// const API_URL = 'http://localhost:5000/api/';

export default {
  login({ commit }, user) {
      return axios.post(API_URL + 'Auth/Login', {
        userName: user.username,
        password: user.password,
      }).then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
          commit('setCurrentUser', response.data.user);
        }

        return response.data;
      });
  },

  logout({ commit }) {
    localStorage.removeItem('user');
    commit('setCurrentUser', {});
  },

  getTours({ commit }) {
    return new Promise((resolve,reject)=>{
        axios.get(API_URL + 'Tour/GetToursForAdmin?PageNumber=1&PageSize=20', { headers: authHeader() })
        .then((response)=>{
            commit('setTours', response.data);
            resolve(response)
        })
        .catch((error)=>{
            reject(error)
        })
    })
  },

  // eslint-disable-next-line no-unused-vars
  createTour({ commit }, { userId, payload }) {
    return new Promise((resolve,reject)=>{
      axios.post(`${API_URL}Tour/CreateTour/${userId}`, payload, { headers: authHeader() })
      .then((response)=>{
          resolve(response)
          return response.data;
      })
      .catch((error)=>{
          reject(error)
      })
    })
  },

  // eslint-disable-next-line no-unused-vars
  updateTour({ commit }, { id, payload }) {
    return new Promise((resolve,reject)=>{
      axios.put(`${API_URL}Tour/UpdateTour/${id}`, payload, { headers: authHeader() })
      .then((response)=>{
          resolve(response)
      })
      .catch((error)=>{
          reject(error)
      })
    })
  },

  getTourById({ commit }, id) {
    return new Promise((resolve,reject)=>{
      axios.get(`${API_URL}Tour/GetTour/${id}`, { headers: authHeader() })
      .then((response)=>{
          commit('setCurrentTour', response.data);
          resolve(response)
      })
      .catch((error)=>{
          reject(error)
      })
    })
  },

  getFoodPackages({ commit }) {
    axios.get(`${API_URL}Tour/GetFoodPackages`).then(({ data }) => {
      commit('setFoodPackages', data);
      return data;
    });
  },

  getActivityLevels({ commit }) {
    axios.get(`${API_URL}Tour/GetActivityLevel`).then(({ data }) => {
      commit('setActivityLevels', data);
      return data;
    });
  },

  getActivities({ commit }) {
    axios.get(`${API_URL}Tour/GetActivities`).then(({ data }) => {
      commit('setActivities', data);
      return data;
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteTourPlan({ commit }, id) {
    return new Promise((resolve,reject)=>{
      axios.delete(`${API_URL}Tour/DeleteTourPlan/${id}`, { headers: authHeader() })
      .then((response)=>{
          // commit('localDeleteTourPlan', response.data);
          resolve(response)
      })
      .catch((error)=>{
          reject(error)
      })
    })
  },

  // eslint-disable-next-line no-unused-vars
  addTourPlan({ commit }, payload) {
    return axios.post(`${API_URL}Tour/AddPlanAktivnosti/${payload.tourId}`, payload, { headers: authHeader() })
      .then(response => {
        // commit('localRemoveTourProp', {propName, value});
        return response.data;
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateTourActivities({ commit }, { tourId, activityIds }) {
    return axios.patch(`${API_URL}Tour/UpdateTourActivities/${tourId}`, activityIds, { headers: authHeader() })
      .then(response => {
        // commit('localRemoveTourProp', {propName, value});
        return response.data;
    });
  },

  // eslint-disable-next-line no-unused-vars
  setMainPhoto({ commit }, { tourId, id}) {
    return axios.post(`${API_URL}Photo/SetMainPhoto/${id}?tourId=${tourId}`,  {queryData: "dataValue"}, { headers: authHeader() })
      .then(response => {
        // commit('localRemoveTourProp', {propName, value});
        return response.data;
    });
  },

  // eslint-disable-next-line no-unused-vars
  setThumbPhoto({ commit }, { tourId, id}) {
    return axios.post(`${API_URL}Photo/SetThumbPhoto/${id}/setThumb?tourId=${tourId}`,  {queryData: "dataValue"}, { headers: authHeader() })
      .then(response => {
        // commit('localRemoveTourProp', {propName, value});
        return response.data;
    });
  },

  // eslint-disable-next-line no-unused-vars
  setSliderPhoto({ commit }, { tourId, id}) {
    return axios.post(`${API_URL}Photo/SetSliderPhoto/${id}/setSlider?tourId=${tourId}`, {queryData: "dataValue"}, { headers: authHeader() })
      .then(response => {
        // commit('localRemoveTourProp', {propName, value});
        return response.data;
    });
  },

  // eslint-disable-next-line no-unused-vars
  deletePhoto({ commit }, { tourId, id}) {
    return axios.delete(`${API_URL}Photo/DeletePhoto/${id}?tourId=${tourId}`, { headers: authHeader() })
      .then(response => {
        // commit('localRemoveTourProp', {propName, value});
        return response.data;
    });
  },

  // eslint-disable-next-line no-unused-vars
  uploadTourImage({ commit }, { tourId, file}) {
    const formData = new FormData();
    formData.append('File', file);
    return axios.post(`${API_URL}Photo/AddPhotoForTour?tourId=${tourId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...authHeader(),
        },
      })
      .then(response => {
        // commit('localRemoveTourProp', {propName, value});
        return response.data;
    });
  },

  /* BLOG METHODS */

  // eslint-disable-next-line no-unused-vars
  createBlog({ commit }, payload) {
    return axios.post(`${API_URL}Blogs/Add`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...authHeader(),
        },
      })
      .then(response => {
        // commit('localRemoveTourProp', {propName, value});
        return response.data;
    });
  },

  getBlogs({ commit }) {
    return new Promise((resolve,reject)=>{
        axios.get(API_URL + 'Blogs/GetAllBlogs', { headers: authHeader() })
        .then((response)=>{
            commit('setBlogs', response.data);
            resolve(response)
        })
        .catch((error)=>{
            reject(error)
        })
    })
  },

  // eslint-disable-next-line no-unused-vars
  updateBlog({ commit }, { id, payload }) {
    return new Promise((resolve,reject)=>{
      axios.put(`${API_URL}Blogs/UpdateBlog/${id}`, payload, { headers: authHeader() })
      .then((response)=>{
          resolve(response)
      })
      .catch((error)=>{
          reject(error)
      })
  })
  },

  getBlogById({ commit }, id) {
    return new Promise((resolve,reject)=>{
      axios.get(`${API_URL}Blogs/GetBlogById?id=${id}`, { headers: authHeader() })
      .then((response)=>{
          commit('setCurrentBlog', response.data);
          resolve(response)
      })
      .catch((error)=>{
          reject(error)
      })
    })
  },

  // eslint-disable-next-line no-unused-vars
  deleteBlog({ commit }, id) {
    return new Promise((resolve,reject)=>{
      axios.delete(`${API_URL}Blogs/Delete/${id}`, { headers: authHeader() })
      .then((response)=>{
          resolve(response)
      })
      .catch((error)=>{
          reject(error)
      })
    })
  },

  /* GALLERY METHODS */

  // eslint-disable-next-line no-unused-vars
  uploadGalleryImage({ commit }, file) {
    const formData = new FormData();
    formData.append('File', file);
    return axios.post(`${API_URL}Gallery/AddPhoto`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...authHeader(),
        },
      })
      .then(response => {
        return response.data;
    });
  },


  getGallery({ commit }) {
    return new Promise((resolve,reject)=>{
        axios.get(API_URL + 'Gallery/GetAllPhotos', { headers: authHeader() })
        .then((response)=>{
            commit('setGalleryPhotos', response.data);
            resolve(response)
        })
        .catch((error)=>{
            reject(error)
        })
    })
  },

  // eslint-disable-next-line no-unused-vars
  activeGalleryPhoto({ commit }, id) {
    return axios.post(`${API_URL}Gallery/SetActivePhoto/${id}`, {queryData: "dataValue"}, { headers: authHeader() })
      .then(response => {
        return response.data;
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteGalleryPhoto({ commit }, id) {
    return axios.delete(`${API_URL}Gallery/DeletePhoto/${id}`, { headers: authHeader() })
      .then(response => {
        return response.data;
    });
  },

  setAddingTour({ commit }, value) {
    commit('setAddingTour', value);
  },
};