import { createApp } from "vue";
import store from './store';
import router from './router';

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

const app = createApp(App);
app.use(router);
app.use(store);
app.mount('#app');
