<template>
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
    >
      <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">{{ tourForAddEdit.name }}</h6>
          <button
            @click="save"
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
          >
            Spremi
          </button>
        </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Detalji ture
          </h6>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Naziv
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="tourForAddEdit.name"
                />
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Naziv EN
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="tourForAddEdit.nameEn"
                />
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Nova cijena
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="tourForAddEdit.newPrice"
                />
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <input id="recomended" type="checkbox" v-model="tourForAddEdit.forRecomended" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label htmlFor="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 uppercase text-blueGray-600 text-xs font-bold mb-2">Preporučena</label>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label for="description" class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Kratki opis ture</label>
                <textarea id="description" rows="3"
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write your thoughts here..." v-model="tourForAddEdit.shortDescription"></textarea>
              </div>
            </div>
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label for="description" class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Kratki opis ture EN</label>
                <textarea id="description" rows="3"
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write your thoughts here..." v-model="tourForAddEdit.shortDescriptionEn"></textarea>
              </div>
            </div>
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label for="description" class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Opis ture</label>
                <textarea id="description" rows="6"
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write your thoughts here..." v-model="tourForAddEdit.description"></textarea>
              </div>
            </div>
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label for="description" class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Opis ture EN</label>
                <textarea id="description" rows="6"
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write your thoughts here..." v-model="tourForAddEdit.descriptionEn"></textarea>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Oprema
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="tourForAddEdit.staPonjetiOprema"
                />
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Oprema EN
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="tourForAddEdit.staPonjetiOpremaEn"
                />
              </div>
            </div>
          </div>
  
          <div class="flex flex-wrap">
            <div class="w-full lg:w-3/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Broj osoba MIN
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="tourForAddEdit.brojOsobaMin"
                />
              </div>
            </div>
            <div class="w-full lg:w-3/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Broj osoba MAX
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="tourForAddEdit.brojOsobaMax"
                />
              </div>
            </div>
            <div class="w-full lg:w-3/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Godine MAX
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="tourForAddEdit.godineMin"
                />
              </div>
            </div>
            <div class="w-full lg:w-3/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Godine min
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="tourForAddEdit.godineMax"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-wrap">
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Mjesto početka
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="tourForAddEdit.mjestoStart"
                />
              </div>
            </div>
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Mjesto završetka
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="tourForAddEdit.mjestoFinish"
                />
              </div>
            </div>
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Trajanje (sati)
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="tourForAddEdit.trajanje"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Preporučeni period
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="tourForAddEdit.preporuceniPeriod"
                />
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Preporučeni period EN
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="tourForAddEdit.preporuceniPeriodEn"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-wrap">
            <div class="w-full lg:w-3/12 px-4">
              <div class="relative w-full mb-3">
                <input id="recomended" type="checkbox" v-model="tourForAddEdit.oprema" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label htmlFor="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 uppercase text-blueGray-600 text-xs font-bold mb-2">Oprema</label>
              </div>
            </div>
            <div class="w-full lg:w-3/12 px-4">
              <div class="relative w-full mb-3">
                <input id="recomended" type="checkbox" v-model="tourForAddEdit.transport" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label htmlFor="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 uppercase text-blueGray-600 text-xs font-bold mb-2">Prevoz</label>
              </div>
            </div>
            <div class="w-full lg:w-3/12 px-4">
              <div class="relative w-full mb-3">
                <input id="recomended" type="checkbox" v-model="tourForAddEdit.visible" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label htmlFor="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 uppercase text-blueGray-600 text-xs font-bold mb-2">Aktivna</label>
              </div>
            </div>
            <div class="w-full lg:w-3/12 px-4">
              <div class="relative w-full mb-3">
                <input id="recomended" type="checkbox" v-model="tourForAddEdit.vodic" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label htmlFor="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 uppercase text-blueGray-600 text-xs font-bold mb-2">Vodič</label>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap">
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label htmlFor="categories" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 uppercase text-blueGray-600 text-xs font-bold mb-2">Kategorija ture</label>
                <select
                  v-model="tourForAddEdit.tourCategoryId"
                  id="categories"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  <option
                    v-for="option in tourCategory"
                    :value="option.id"
                    :key="option.id">
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label htmlFor="categories" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 uppercase text-blueGray-600 text-xs font-bold mb-2">Obrok</label>
                <select
                  v-model="tourForAddEdit.foodPackageId"
                  id="categories"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  <option
                    v-for="option in foodPackages"
                    :value="option.foodPackageId"
                    :key="option.foodPackageId">
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label htmlFor="categories" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 uppercase text-blueGray-600 text-xs font-bold mb-2">Nivo aktivnosti</label>
                <select
                  v-model="tourForAddEdit.activityLevelId"
                  id="categories"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  <option
                    v-for="option in activityLevels"
                    :value="option.activityLevelId"
                    :key="option.activityLevelId">
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
<script>

export default {
  name: 'AddEditTourDetails',
  data() {
    return {
      tourCategorySelected: null,
      tourForAddEdit: {},
    };
  },

  components: {
  },

  props: {
    tour: {
      type: Object,
      required: false,
      default: (() => {}),
    },
  },

  beforeDestroy() {
  },

  created() {
    this.$nextTick(() => {
      if (this.$route.params?.id !== 'newtour') {
        Object.assign(this.tourForAddEdit, this.tour)
      }
    });
  },

  computed: {
    tourCategory() {
      return this.$store.getters.tourCategory;
    },
    foodPackages() {
      return this.$store.getters.foodPackages;
    },
    activityLevels() {
      return this.$store.getters.activityLevels;
    },
/*     currentTour() {
			return this.$store.getters.currentTour || {};
		}, */

    addingTour() {
      return this.$route.params?.id === 'newtour';
    },

    currentUserId() {
      return this.$store.getters.currentUser.id;
    },
  },

  methods: {
    autoCompareValues(obj1, obj2) {
      var differences = {};
      for (var key in obj1) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj1.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
              differences[key] = obj1[key];
          }
      }
      return differences;
    },

    save() {
      if (this.tour) {
        const payload = {
          Name: this.tourForAddEdit.name,
          NameEn: this.tourForAddEdit.nameEn,
          ShortDescription: this.tourForAddEdit.shortDescription,
          ShortDescriptionEn: this.tourForAddEdit.shortDescriptionEn,
          Description: this.tourForAddEdit.description,
          DescriptionEn: this.tourForAddEdit.descriptionEn,
          Visible: this.tourForAddEdit.visible,
          NewPrice: this.tourForAddEdit.newPrice,
          OldPrice: this.tourForAddEdit.oldPrice,
          Discount: this.tourForAddEdit.discount,
          GodineMin: this.tourForAddEdit.godineMin,
          GodineMax: this.tourForAddEdit.godineMax,
          BrojOsobaMin: this.tourForAddEdit.brojOsobaMin,
          BrojOsobaMax: this.tourForAddEdit.brojOsobaMax,
          Trajanje: this.tourForAddEdit.trajanje,
          PreporuceniPeriod: this.tourForAddEdit.preporuceniPeriod,
          PreporuceniPeriodEn: this.tourForAddEdit.preporuceniPeriodEn,
          StaPonjetiOprema: this.tourForAddEdit.staPonjetiOprema,
          StaPonjetiOpremaEn: this.tourForAddEdit.staPonjetiOpremaEn,
          MjestoStart: this.tourForAddEdit.mjestoStart,
          MjestoFinish: this.tourForAddEdit.mjestoFinish,
          Oprema: this.tourForAddEdit.oprema,
          Vodic: this.tourForAddEdit.vodic,
          Transport:this. tourForAddEdit.transport,
          ActivityLevelId: this.tourForAddEdit.activityLevelId,
          FoodPackageId: this.tourForAddEdit.foodPackageId,
          TourCategoryId: this.tourForAddEdit.tourCategoryId,
          IsDzDz: this.tourForAddEdit.isDzDz,
          ForRecomended: this.tourForAddEdit.forRecomended,
        };
        if (this.addingTour) {
          this.$store.dispatch('createTour', {userId: this.currentUserId, payload}).then(( { data } ) => {
            this.openTour(data.tour.tourId);
            this.$store.dispatch('getTours');
          });
        } else {
          const differences = this.autoCompareValues(payload, this.tour);
          if (Object.keys(differences).length > 0) {
            this.$store.dispatch('updateTour', { id: this.tour.tourId, payload: differences });
          }
        }
      }
    },

    openTour(id) {
      this.$store.dispatch('getTourById', id).then(() => {
        this.$router.push({ name: 'tour', params: { id } });
      });
    },
  },

  watch: {
    /* currentTour(newVal) {
      console.log('watch', newVal);
    }, */
    '$route.params.id': {
			immediate: true,
			handler (newVal) {
				if (newVal === 'newtour') {
					this.tourForAddEdit = {};
				}
			}
		}
  },
};

</script>  