const mutations = {
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    setTours(state, tours) {
        state.tours = tours;
    },
    setBlogs(state, blogs) {
      state.blogs = blogs;
    },
    setCurrentTour(state, tour) {
      state.currentTour = tour;
    },
    setCurrentBlog(state, blog) {
      state.currentBlog = blog;
    },
    setFoodPackages(state, payload) {
      state.foodPackages = payload;
    },
    setActivities(state, payload) {
      state.activities = payload;
    },
    setActivityLevels(state, payload) {
      state.activityLevels = payload;
    },
    setGalleryPhotos(state, payload) {
      state.galleryPhotos = payload;
    },
    setAddingTour(state, payload) {
      state.addingTour = payload;
    }
};

export default mutations;